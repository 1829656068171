import Home from "./views/Front/Home/Home";
import StaticContent from "./views/Front/StaticContent";
import Login from "./views/Front/Login/Login";
import ForgotPassword from "./views/Front/Login/ForgotPassword";
import ChangePassword from "./views/Front/Login/ChangePassword";
import AboutUs from "./views/Front/AboutUs";

const commonMenu = [];

const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    component: Home,
    leftMenu: commonMenu
  },
  {
    path: "/about-us",
    exact: true,
    name: "AboutUs",
    component: AboutUs,
    leftMenu: commonMenu
  },
  {
    path: "/login",
    exact: true,
    name: "Login",
    component: Login,
    leftMenu: commonMenu
  },
  {
    path: "/forgot-password",
    exact: true,
    name: "ForgotPassword",
    component: ForgotPassword,
    leftMenu: commonMenu
  },
  {
    path: "/reset-password/:token",
    exact: true,
    name: "ChangePassword",
    component: ChangePassword,
    leftMenu: commonMenu
  },
  {
    path: "/dashboard",
    exact: true,
    name: "Home",
    component: Home,
    leftMenu: commonMenu
  },
  {
    path: "/page/:url",
    exact: true,
    name: "Static Content",
    component: StaticContent,
    protected: true,
    leftMenu: commonMenu
  },
  { path: "/admin/dashboard", exact: true, name: "Admin" }
];

export default routes;
