import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class AboutUs extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>About Us - Dental Tracks</title>
        </Helmet>
        <section className="banner-section about-us-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pl-lg-0">
                <h2 className="inner-heading">About Us</h2>
              </div>
            </div>
          </div>
        </section>

        <section className="disruption-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mx-auto text-center title-part">
                <h2>DentalTracks</h2>
                <p>
                  A web-based program that allows patients to be referred
                  digitally to Dental Specialists and other members of their
                  Treatment Team to eliminate the wasted time and confusion that
                  exists with traditional dental referral procedures.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 custom-description">
                <h2>
                  <span style={{ fontSize: 18, fontWeight: 400 }}>
                    DentalTracks was developed by a group of dentists and dental
                    professionals who saw how inefficient the current model of
                    referring dental patients was. The biggest problems the
                    group set out to fix were:
                  </span>
                </h2>
                &nbsp;
                <ul>
                  <li>
                    <p>
                      Many dental patients fail to even schedule their
                      consultations with a Dental Specialist
                    </p>
                  </li>
                  <li>
                    <p>
                      Communication between the Dental Specialist and the
                      General Dentist is too often incomplete, causing
                      complications with the patient’s treatment
                    </p>
                  </li>
                  <li>
                    <p>
                      Current patient management systems do not communicate with
                      each other, causing time-consuming processes to share
                      information&nbsp;
                    </p>
                  </li>
                  <li>
                    <p>
                      &#8203;Patients are often left out of their treatment plan
                      steps and become frustrated
                      <br />
                      &nbsp;
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 custom-description">
                <img
                  src={require("../../../assets/images/about-1.jpg")}
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 custom-description">
                <img
                  src={require("../../../assets/images/about-2.jpg")}
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-5 custom-description">
                <h2>
                  <span style={{ fontSize: 18, fontWeight: 400 }}>
                    DentalTracks is the solution to these common problems. Now
                    all information regarding a patient’s treatment is located
                    in one place, accessible from any internet-capable
                    device.&nbsp;
                  </span>
                </h2>
                <p>
                  <br />
                  Incompatibility of different patient management systems is
                  able to be worked around by allowing for easy upload of any
                  file or image pertinent to a patient’s treatment.&nbsp;
                  <br />
                  <br />
                  The patient portal allows a patient to see what the next steps
                  of their treatment are and to see any information or messages
                  that their Treatment Team shares with them, making them feel a
                  part of the process and well-informed.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="artical-part">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <p>
                  At DentalTracks, our goal is to make dental patient referrals
                  an efficient, easy process that results in a better patient
                  experience and outcome.
                  <br />
                  <br />
                  Better Referrals. Better Communication. Better Dentistry.
                  <br />
                  &nbsp;
                </p>
                <Link className="btn-outline-primary" to="/login">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    userType: state.userType
  };
};
export default connect(mapStateToProps)(AboutUs);
