import React, { Component } from "react";
import { connect } from "react-redux";
import { Fade } from "reactstrap";

class ShowHideBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [true, false, false, false]
    };
  }
  toggleImage = e => {
    let imgIndex = e.target.dataset.index;
  };
  render() {
    return (
      <React.Fragment>
        <div className="col-lg-5 col-lg-push-1">
          <Fade in={this.state.images[0]} className="disuption-images-box">
            <img
              id="p_lt_ctl00_pageplaceholder_p_lt_ctl12_EditableImage4_ucEditableImage_imgImage"
              className="img-fluid imagedefault active"
              src={require("../../../assets/images/disruptionWOwatermark_6.jpg")}
              alt=""
            />
          </Fade>
          {/* <Fade in={this.state.images[1]}>
            <img
              id="p_lt_ctl00_pageplaceholder_p_lt_ctl12_EditableImage5_ucEditableImage_imgImage"
              className="img-fluid image1"
              src={require("../../../assets/images/DT_Slide_0001.png")}
              alt=""
            />
          </Fade>
          <Fade in={this.state.images[2]}>
            <img
              id="p_lt_ctl00_pageplaceholder_p_lt_ctl12_EditableImage6_ucEditableImage_imgImage"
              className="img-fluid image2"
              src={require("../../../assets/images/DT_Slide_0002.png")}
              alt=""
            />
          </Fade>
          <Fade in={this.state.images[3]}>
            <img
              id="p_lt_ctl00_pageplaceholder_p_lt_ctl12_EditableImage7_ucEditableImage_imgImage"
              className="img-fluid image3"
              src={require("../../../assets/images/DT_Slide_0003.png")}
              alt=""
            />
          </Fade> */}
        </div>
        <div className="col-lg-5 col-lg-push-1">
          <div className="disruption-box-part box-1">
            <div className="disruption-box">
              <div className="inner-disruption">
                <h3>
                  An estimated 40% of patients never schedule their consultation
                  with Dental Specialists.
                </h3>
              </div>
              <div
                className="hover-disuption"
                onMouseEnter={this.toggleImage}
                data-index={0}
              >
                DentalTracks allows Dental Specialists to see who has been
                referred to them in real time so they can proactively pursue all
                of their referrals.
                <a className="clslightbox" href="#titlelightbox1">
                  Read more
                </a>
              </div>
            </div>
          </div>
          <div className="disruption-box-part box-2">
            <div className="disruption-box">
              <div className="inner-disruption">
                <h3>
                  Of over 300 patient management systems, none allow
                  inter-system communication.
                </h3>
              </div>
              <div
                className="hover-disuption"
                onMouseEnter={this.toggleImage}
                data-index={1}
              >
                DentalTracks allows for seamless communication and file sharing
                regardless of what patient management system your office is
                using.
                <a className="clslightbox" href="#titlelightbox2">
                  Read more
                </a>
              </div>
            </div>
          </div>
          <div className="disruption-box-part box-3">
            <div className="disruption-box">
              <div className="inner-disruption">
                <h3>
                  Sending treatment communication letters is time-consuming and
                  expensive.
                </h3>
              </div>
              <div
                className="hover-disuption"
                onMouseEnter={this.toggleImage}
                data-index={2}
              >
                DentalTracks eliminates the need for outdated communication
                methods by having all information around a patient’s treatment
                in one location.
                <a className="clslightbox" href="#titlelightbox3">
                  Read more
                </a>
              </div>
            </div>
          </div>
          <div className="disruption-box-part box-4">
            <div className="disruption-box">
              <div className="inner-disruption">
                <h3>
                  Dental Specialist patient management systems do not offer
                  tools that can be used to optimize patient flow.
                </h3>
              </div>
              <div
                className="hover-disuption"
                onMouseEnter={this.toggleImage}
                data-index={3}
              >
                DentalTracks uses PracticeTracks technology to show you how
                efficiently patients are flowing through your practice so you
                can maximize the productivity you get from each referral.
                <a className="clslightbox" href="#titlelightbox4">
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    userType: state.userType
  };
};
export default connect(mapStateToProps)(ShowHideBox);
