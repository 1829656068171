import React, { Component } from "react";
import { Link, Redirect, Route, Switch, NavLink } from "react-router-dom";
import "../../Front.scss";
import routes from "../../front-routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";

class FrontLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true
    };
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <header className="home-page main-header">
          <nav className="navbar navbar-expand-lg fixed-top navbar-dark indigo">
            <div className="container px-0">
              <Link className="navbar-brand" to="/">
                <img src={`/assets/images/logo.png`} alt="Dental Care"
                  title="Dental Care" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarTogglerDemo02"
              >
                <ul className="navbar-nav custom-nav mr-auto mt-lg-0">
                  <li className="nav-item active">
                    <Link className="nav-link" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact-us">
                      Contact Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/login">
                      Sign Up/Login
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>

        <Switch>
          {routes.map((route, idx) => {
            return route.component ? (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => <route.component {...props} />}
              />
            ) : null;
          })}
        </Switch>

        <footer className="pt-md-5 pb-md-3">
          <div className="container">
            <div className="row">
              <hr />
              <div className="col-lg-8 col-md-8">
                <p className="pl-lg-5">
                  Copyright © {new Date().getFullYear()} Dental Tracks. All
                  rights reserved.
                </p>
              </div>
              <div className="col-lg-4 col-md-4">
                <p className="footer-bottom-nav-link">
                  <a href="Privacy-Policy.html">Privacy Policy</a>
                  <a href="Terms-Of-Use.html"> Terms of Use </a>
                  <a href="Sitemap.html"> Site Map</a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
export default FrontLayout;
