import React, { Component } from "react";
import { doLogin } from "../../../store/actions";
import EmailVarificationModal from "./EmailVarificationModal";
import http from "../../../services/http";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  Fade,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,Col,Spinner,FormText
} from "reactstrap";
import { Link } from "react-router-dom"

const mapStateToProps = state => {
  return {
    userId: state.userId,
    userType: state.userType
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doLogin: data => {
      dispatch(doLogin(data));
    }
  };
};
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      remember_me: false,
      loader: false,
      error: false,
      isFormValid: false,
      httpReferer: window.document.referer ? window.document.referer : "",
      loginBox:true,
      otpBox:false,
      otp:""
    };
  }

  validateLoginForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Please enter your username or email.";
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Please enter your password.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  validateOtpForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["otp"]) {
      formIsValid = false;
      errors["otp"] = "Please enter otp received on your mobile.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  submitLoginForm = e => {
    e.preventDefault();
    if (this.validateLoginForm()) {
      this.setState({ loader: true });
      let reqData = {
        username: this.state.fields["username"],
        password: this.state.fields["password"]
      };
      var that = this;
      http.post("/user/login", reqData).then(function (response) {
        that.setState({ loader: false });
        if (response.data.error) {
          let errors = {};
          errors["password"] = response.data.error;
          that.setState({ errors });
        } else if (response.data.success) {
          that.setState({loginBox:false,otpBox:true,fields:{email:response.data.email},otp:response.data.code});         
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  };

  submitOtpForm = e => {
    e.preventDefault();
    if (this.validateOtpForm()) {
      this.setState({ loader: true });
      let reqData = {
        email: this.state.fields["email"],
        otp: this.state.fields["otp"]
      };
      var that = this;
      http.post("/user/validate-otp", reqData).then(function (response) {
        that.setState({ loader: false });
        if (response.data.error) {
          let errors = {};
          errors["otp"] = response.data.message;
          that.setState({ errors });
        } else if (response.data.success) {
          let eagleSoftDetails = {};
          localStorage.clear();
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userType", response.data.user_type);
          localStorage.setItem("userName", response.data.username);
          localStorage.setItem("userId", response.data.user_id);
          localStorage.setItem("clinicId", response.data.clinic_id);
          localStorage.setItem("userImage", response.data.image);
          localStorage.setItem("name", response.data.name);
          if(response.data.eagleSoftDetails !== undefined){
            eagleSoftDetails = JSON.stringify(response.data.eagleSoftDetails);
            localStorage.setItem("eagleSoftDetails", eagleSoftDetails);
          }
          that.props.doLogin({
            token: response.data.token,
            authenticated: true,
            userName: response.data.username,
            userId: response.data.user_id,
            clinicId: response.data.clinic_id,
            userType: response.data.user_type,
            userImage: response.data.image,
            name: response.data.name,
            eagleSoftDetails:eagleSoftDetails
          });
          that.props.history.push("/admin/dashboard");
          /* if (response.data.user_type === 1) {
            that.props.history.push("/admin/dashboard");
          } else {
            //alert(that.state.httpReferer);
            that.props.history.push("/dashboard");
          } */          
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  };

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.userId) {
      this.props.history.push("/admin/dashboard");
    }
  }

  render() {
    const { loader } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Login : Dental Tracks</title>
        </Helmet>

        <section className="login-enter-section mb-md-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                {this.state.loginBox && <Fade in={this.state.loginBox}>
                  <div className="left-side-login">
                    <h2 className="mb-3">Login to your Account</h2>
                    <form
                      className="login-form"
                      method="post"
                      onSubmit={this.submitLoginForm}
                    >
                      <FormGroup>
                        <Label for="username">Username Or Email</Label>
                        <Input
                          className="input-bg"
                          type="text"
                          name="username"
                          id="username"
                          value={
                            this.state.fields["username"]
                              ? this.state.fields["username"]
                              : ""
                          }
                          onChange={event => this.handleChange("username", event)}
                          invalid={this.state.errors["username"] ? true : false}
                        />
                        {this.state.errors["username"] && (
                          <FormFeedback>
                            {this.state.errors["username"]}
                          </FormFeedback>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label for="password">Password</Label>
                        <Input
                          className="input-bg"
                          type="password"
                          name="password"
                          id="password"
                          value={
                            this.state.fields["password"]
                              ? this.state.fields["password"]
                              : ""
                          }
                          onChange={event => this.handleChange("password", event)}
                          invalid={this.state.errors["password"] ? true : false}
                        />
                        {this.state.errors["password"] && (
                          <FormFeedback>
                            {this.state.errors["password"]}
                          </FormFeedback>
                        )}
                      </FormGroup>
                      <Row>
                        <Col md={6}>
                          <button
                            className="btn btn-primary artical-button"
                            type="submit"
                            disabled={loader}
                          >
                            {loader && (
                              <Spinner
                                size="sm"
                                color="#887d7d"
                                className="mr-1"
                              />
                            )}
                            Log in
                          </button>
                        </Col>
                        <Col md={6} className="pt-3">
                          <Link to="/forgot-password">Forgot Password?</Link>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Fade>}
                {this.state.otpBox && <Fade in={this.state.otpBox}>
                  <div className="left-side-login">
                    <h2 className="mb-3">Verify your mobile number.({this.state.otp})</h2>
                    <form
                      className="otp-form"
                      method="post"
                      onSubmit={this.submitOtpForm}
                    >
                      <FormGroup>
                        <Label for="code">Enter 6-digit code you received </Label>
                        <Input
                          className="input-bg"
                          type="number"
                          name="otp"
                          id="otp"
                          value={
                            this.state.fields["otp"]
                              ? this.state.fields["otp"]
                              : ""
                          }
                          onChange={event => this.handleChange("otp", event)}
                        />
                        {this.state.errors["otp"] && (
                          <FormText color="danger" style={{fontSize:13}}>{this.state.errors["otp"]}</FormText>
                        )}
                      </FormGroup>
                      <Row>
                        <Col md={6}>
                          <button
                            className="btn btn-primary artical-button"
                            type="submit"
                            disabled={loader}
                          >
                            {loader && (
                              <Spinner
                                size="sm"
                                color="#887d7d"
                                className="mr-1"
                              />
                            )}
                            Submit
                          </button>
                        </Col>
                        <Col md={6} className="pt-3">
                          <Link to="/forgot-password">Didn't receive?</Link>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Fade>}
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="right-area-login pl-lg-3 pl-0">
                  <h2 className="mt-4 mt-lg-0">
                    Don't have an account? Sign up now.
                  </h2>
                  <p className="mb-md-0 pt-3 mb-0">
                    Are you ready to simplify the referral process while
                    enhancing the benefits of working cohesively as a treatment
                    team? Sign up below! We look forward to helping you take
                    your practice to the next level.
                  </p>
                  <form method="post" onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <FormGroup>
                          <Label for="username">Account Type *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="username">Specialty *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="username">Practice Name *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="username">First Name *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="username">First Name *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="username">Last Name *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="username">Email Address *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="username">Choose Password *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="username">Confirm Password *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <FormGroup>
                          <Label for="username">Address *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="username">Address 2 *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="username">City *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="username">State *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="username">Zip *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="username">Phone *</Label>
                          <Input
                            className="select-btn"
                            type="text"
                            name="account_type"
                            id="account_type"
                            value={
                              this.state.fields["account_type"]
                                ? this.state.fields["account_type"]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange("account_type", event)
                            }
                            invalid={
                              this.state.errors["account_type"] ? true : false
                            }
                          />
                          {this.state.errors["account_type"] && (
                            <FormFeedback>
                              {this.state.errors["account_type"]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <EmailVarificationModal
          show={this.props.match.params.code ? true : false}
          code={
            this.props.match.params.code ? this.props.match.params.code : null
          }
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
