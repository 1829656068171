import React, { Component } from "react";
import page from "../../../services/page";
import NotFound from "../Common/NotFound";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingOverlay from "react-loading-overlay";

class StateContent extends Component {
  state = {
    page: {},
    loader: true,
    notFound: false
  };

  getPage = url => {
    page.getOneByName({ url: url }).then(response => {
      if (response.data.success) {
        this.setState({ loader: false, page: response.data.content });
      } else if (response.data.error) {
        this.setState({ loader: false, notFound: true });
      }
    });
  };
  componentDidMount() {
    if (this.props.match.params.url) {
      this.getPage(this.props.match.params.url);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> Poll : Dental Tracks</title>
        </Helmet>

        <Row className="mt-4">
          <Col md={9} lg={10} className="col-12 pr-4 mt-3">
            <div className="central-meta create-new-group-form item mb-2 pt-3">
              <Row className="multi-comment pb-3">
                <Col md={12} lg={12} className="col-12 mb-3 pl-0">
                  <LoadingOverlay
                    active={this.state.loader}
                    spinner={<Spinner color="dark" />}
                    fadeSpeed={200}
                    classNamePrefix="mitiz"
                  >
                    <h3>{this.state.page.name}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.page.content
                      }}
                    />
                  </LoadingOverlay>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    baseUrl: state.baseUrl
  };
};

export default connect(mapStateToProps)(StateContent);
