import React, { Component } from "react";
import { connect } from "react-redux";
import { Carousel, CarouselItem, CarouselControl, Spinner } from "reactstrap";

class TestimonialCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [
        {
          html: (
            <div className="row">
              <div className="col-md-4">
                <img
                  src={require("../../../assets/images/shutterstock_441095218.jpg")}
                  className="img-circle"
                />
              </div>
              <div className="col-md-8">
                <div className="testimonials-text">
                  <span className="image-part">
                    <img src={require("../../../assets/images/quote.png")} />
                  </span>
                  <span>
                    <div className="testimonial-name">
                      <strong>Dr. Joan Johnson</strong>
                    </div>
                    We've been waiting for a solution like this for a long time.
                    More of our patients are following through with treatment
                    recommendations and the results they’re seeing are much more
                    predictable.
                  </span>
                </div>
              </div>
            </div>
          )
        },
        {
          html: (
            <div className="row">
              <div className="col-md-4">
                <img
                  src={require("../../../assets/images/shutterstock_548225176.jpg")}
                  className="img-circle"
                />
              </div>
              <div className="col-md-8">
                <div className="testimonials-text">
                  <span className="image-part">
                    <img src={require("../../../assets/images/quote.png")} />
                  </span>
                  <span>
                    <div className="testimonial-name">
                      <strong>Dr. Joan Smith</strong>
                    </div>
                    Since we started using DentalTracks.com, we’ve seen a
                    dramatic increase in the quality of patient outcomes due to
                    everyone being on the same page throughout the process.
                  </span>
                </div>
              </div>
            </div>
          )
        }
      ],
      activeIndex: 0,
      animating: false,
      overLayLoader: true
    };
  }
  next = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };
  previous = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };
  setAnimating = animate => {
    this.setState({ animating: animate });
  };
  render() {
    return (
      <section className="testimonials">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto text-center">
              <h2>Testimonials</h2>
              <Carousel
                activeIndex={this.state.activeIndex}
                next={this.next}
                previous={this.previous}
                interval={false}
              >
                {this.state.items.map((item, index) => (
                  <CarouselItem
                    onExiting={() => this.setAnimating(true)}
                    onExited={() => this.setAnimating(false)}
                    key={index}
                  >
                    {item.html}
                  </CarouselItem>
                ))}

                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={this.previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={this.next}
                />
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    userType: state.userType
  };
};
export default connect(mapStateToProps)(TestimonialCarousel);
