import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import DoctorCarousel from "./DoctorsCarousel";
import TestimonialCarousel from "./TestimonialCarousel";
import ShowHideBox from "./ShowHideBox";
class Home extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Home - Dental Tracks</title>
        </Helmet>
        <section className="banner-section">
          <div className="container">
            <div className="row">
              <div className="banner-contant banner-contant-home">
                <div className="banner-title">
                  <div className="banner-inner-title">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8 col-md-12 pl-lg-0">
                          <h2>
                            Better Referrals.
                            <br />
                            Better Communication.
                            <br />
                            <span>Better Dentistry.</span>
                          </h2>
                        </div>
                        <div className="col-lg-8 col-md-12 pl-lg-0">
                          <div className="banner-logo">
                            <img
                              className="img-fluid"
                              src={require("../../../assets/images/heroImageLogo.png")}
                              alt="Dental Tracks"
                            />
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 text-center">
                          <a
                            className="banner-button btn btn-outline-secondary"
                            href="http://portal.dentaltracks.com/"
                          >
                            GET STARTED
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="three-part">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-sm-4 light-blue text-center"
                style={{ heigh: 326 }}
              >
                <img src={require("../../../assets/images/1.png")} alt="" />

                <h3>Electronic Referrals</h3>
                <p>
                  Electronically refer patients from a General Dentist to a
                  Dental Specialist or other Treatment Team members to ensure a
                  seamless treatment process for your patients.
                </p>
              </div>
              <div
                className="col-sm-4 medium-blue text-center"
                style={{ heigh: 326 }}
              >
                <img src={require("../../../assets/images/2.png")} alt="" />
                &nbsp;
                <h3>Instant Communication</h3>
                <p>
                  Instantly share patient forms and notes, diagnostic images,
                  and treatment plans between all members of a patient’s
                  Treatment Team to eliminate the need for expensive,
                  time-consuming communication methods.
                </p>
              </div>
              <div
                className="col-sm-4 dark-blue text-center"
                style={{ heigh: 326 }}
              >
                <img src={require("../../../assets/images/3.png")} alt="" />
                &nbsp;
                <h3>Real-Time Tracking</h3>
                <p>
                  Track a patient’s progress through the referral process in
                  real-time to minimize patient drop-out from treatment plans
                  and to enable timely follow-ups.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="disruption-section">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-lg-12 col-md-12 text-center title-part">
                <div className="problem-to-solutions">
                  <h2>From Problem to Solution</h2>
                  <p>
                    DentalTracks seamlessly addresses the major issues that face
                    the existing referral models.
                  </p>
                </div>
              </div>
              <div className="clearfix"></div>
              <ShowHideBox />
            </div>
          </div>
        </section>
        <section className="icon-bounce">
          <div className="container text-center animatedParent">
            <div className="row">
              <div className="col-lg-12 col-md-12 text-center title-part">
                <div className="problem-to-solutions">
                  <h2>Keeping Tabs</h2>
                  <p>Follow your patient's treatment process in real-time.</p>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="col-lg-12">
                <div className="row">
                  <ul className="list-unstyled list-inline">
                    <li
                      className="col-6 col-md-2 animated growIn go"
                      style={{ heiht: 252, display: "flex" }}
                    >
                      <figure>
                        <img
                          id="p_lt_ctl00_pageplaceholder_p_lt_ctl16_EditableImage9_ucEditableImage_imgImage"
                          className="img-fluid center-block"
                          src={require("../../../assets/images/process_1.png")}
                          alt=""
                        />
                      </figure>
                      <p>Patient Information Received</p>
                    </li>
                    <li
                      className="col-md-2 animate-dots"
                      style={{ height: 252 }}
                    >
                      <div className="fs-dot " style={{ display: "none" }}>
                        <span
                          className="animated fadeIn delay-250"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-500"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-750"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-1000"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                      </div>
                      <div className="sn-dot" style={{ display: "block" }}>
                        <span
                          className="animated fadeIn delay-250 go"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-500 go"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-750 go"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-1000 go"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                      </div>
                    </li>
                    <li
                      className="col-6 col-md-2 animated growIn delay-2250 go"
                      style={{ height: 252, display: "flex" }}
                    >
                      <figure>
                        <img
                          id="p_lt_ctl00_pageplaceholder_p_lt_ctl17_EditableImage10_ucEditableImage_imgImage"
                          className="img-fluid center-block"
                          src={require("../../../assets/images/process_2.png")}
                          alt=""
                        />
                      </figure>
                      <p>
                        Consultation
                        <br />
                        Scheduled/
                        <br />
                        Completed
                      </p>
                    </li>
                    <li
                      className="col-md-2 animate-dots"
                      style={{ height: 252 }}
                    >
                      <div className="fs-dot" style={{ display: "none" }}>
                        <span
                          className="animated fadeIn delay-2500"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-2750"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-3000"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-3250"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                      </div>
                      <div className="sn-dot" style={{ display: "block" }}>
                        <span
                          className="animated fadeIn delay-250 go"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-500 go"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-750 go"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-1000 go"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                      </div>
                    </li>
                    <li
                      className="col-6 col-md-2 animated growIn delay-4750 go"
                      style={{ height: 252, display: "flex" }}
                    >
                      <figure>
                        <img
                          id="p_lt_ctl00_pageplaceholder_p_lt_ctl18_EditableImage11_ucEditableImage_imgImage"
                          className="img-fluid center-block"
                          src={require("../../../assets/images/process_3.png")}
                          alt=""
                        />
                      </figure>
                      <p>
                        Procedure Scheduled/
                        <br />
                        Completed
                      </p>
                    </li>
                    <li
                      className="col-md-2 animate-dots"
                      style={{ height: 252 }}
                    >
                      <div className="fs-dot" style={{ display: "none" }}>
                        <span
                          className="animated fadeIn delay-5000"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-5250"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-5500"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-5750"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                      </div>
                      <div className="sn-dot" style={{ display: "block" }}>
                        <span
                          className="animated fadeIn delay-250 go"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-500 go"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-750 go"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                        <span
                          className="animated fadeIn delay-1000 go"
                          style={{ display: "inline" }}
                        >
                          •
                        </span>
                      </div>
                    </li>
                    <li
                      className="col-sm-6 col-md-2 animated growIn delay-7000 go"
                      style={{ height: 252, display: "flex" }}
                    >
                      <figure>
                        <img
                          id="p_lt_ctl00_pageplaceholder_p_lt_ctl19_EditableImage12_ucEditableImage_imgImage"
                          className="img-fluid center-block"
                          src={require("../../../assets/images/process-4WOwatermark.png")}
                          alt=""
                        />
                      </figure>
                      <p>
                        Patient
                        <br />
                        Treatment Complete
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="process">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center title-part">
                <div className="optimize">
                  <h2>Optimize Patient Flow</h2>
                  <p>
                    Connect and communicate with the patient and all members of
                    the treatment team in one centralized place to ensure a
                    smooth process.
                  </p>
                </div>
              </div>
              <div className="col-lg-10 mx-auto col-md-12 d-none">
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <div className="c100 p75 big">
                      <span>
                        <img
                          id="p_lt_ctl00_pageplaceholder_p_lt_ctl21_E1_ucEditableImage_imgImage"
                          src="images/bag.png"
                          alt=""
                        />
                      </span>
                      <div className="slice">
                        <div className="bar"></div>
                        <div className="fill"></div>
                      </div>
                    </div>
                    <div className="process-text">
                      <p>Experience</p>
                      <h2>75%</h2>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="c100 p70 big">
                      <span>
                        <img
                          id="p_lt_ctl00_pageplaceholder_p_lt_ctl23_E2_ucEditableImage_imgImage"
                          src="images/bag.png"
                          alt=""
                        />
                      </span>
                      <div className="slice">
                        <div className="bar"></div>
                        <div className="fill"></div>
                      </div>
                    </div>
                    <div className="process-text">
                      <p>Experience</p>
                      <h2>75%</h2>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="c100 p50 big">
                      <span>
                        <img
                          id="p_lt_ctl00_pageplaceholder_p_lt_ctl25_E3_ucEditableImage_imgImage"
                          src="images/bag.png"
                          alt=""
                        />
                      </span>
                      <div className="slice">
                        <div className="bar"></div>
                        <div className="fill"></div>
                      </div>
                    </div>
                    <div className="process-text">
                      <p>Experience</p>
                      <h2>75%</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <DoctorCarousel />
        <section className="artical-part">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="Reduce">
                  <h2>Reduce Patient Drop-Out</h2>
                  <p>
                    Are you ready to simplify the referral process while
                    enhancing the benefits of working cohesively as a treatment
                    team?
                    <br />
                    &nbsp;
                  </p>
                  <a
                    className="btn-outline-primary"
                    href="http://portal.dentaltracks.com/"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <TestimonialCarousel />
        <section className="artical-part gray-part custom-artical">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <img
                  className="gray-logo-image"
                  src={require("../../../assets/images/gray-logo.png")}
                />
                <p>
                  Don't miss the opportunity to maximize your dental referral
                  process...
                </p>
                <h2>Take the first step today!</h2>
                <br />
                <a className="btn btn-outline-primary" href="/Contact-Us">
                  Send Request
                </a>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    userType: state.userType
  };
};
export default connect(mapStateToProps)(Home);
